@use '../../styles/mixins' as mixins;
@use '../../styles/breakpoints' as bp;

.triple-column-module {
    
    flex: 0 1 calc(25% - var(--wp--preset--spacing--50));
    display: flex;
    flex-wrap: wrap;
    gap: var(--wp--preset--spacing--50);

    &:not(:last-child) {
        margin-block-end: var(--wp--preset--spacing--50);
    }

    @media only screen and (max-width: bp.$breakpoint-extra-small) {
        gap: var(--wp--preset--spacing--30);
    }

    h3 {
        font-size: var(--wp--preset--font-size--heading-xs);
        color: var(--wp--preset--color--bnpp-green);
        flex: 0 1 25%;
        margin: 0;
    
        @media only screen and (max-width: bp.$breakpoint-extra-small) {
            flex: 1 1 45%;
        }
    }

    p {
        color: var(--wp--preset--color--bnpp-black);
        text-wrap: pretty;
        flex: 1 1 200px;
        margin: 0;
        &:not(:last-child) {
            margin-block-end: var(--wp--preset--spacing--40);
        }
    }

    &.dark {

        h3 {
            color: #7FD4B5!important;
        }
        
    }

}
